/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * https://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * mono45-headline:
 *   - https://typekit.com/eulas/00000000000000003b9b41a3
 *   - https://typekit.com/eulas/00000000000000003b9b41a4
 *   - https://typekit.com/eulas/00000000000000003b9b41a2
 * ropa-sans-pro:
 *   - https://typekit.com/eulas/00000000000000003b9af9ad
 *   - https://typekit.com/eulas/00000000000000003b9af9ae
 *   - https://typekit.com/eulas/00000000000000003b9af9af
 *   - https://typekit.com/eulas/00000000000000003b9af9b0
 *   - https://typekit.com/eulas/00000000000000003b9af9b3
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-06-05 03:05:57 UTC"}*/

/* @import url("https://p.typekit.net/p.css?s=1&k=dgb6ahc&ht=tk&f=15531.34413.44274.34146.34147.34148.34149.34152&a=4631536&app=typekit&e=css"); */

@font-face {
  font-family: 'mono45-headline';
  src: url('https://use.typekit.net/af/48e3e7/00000000000000003b9b41a3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
    format('woff2');
  /* url('https://use.typekit.net/af/48e3e7/00000000000000003b9b41a3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/48e3e7/00000000000000003b9b41a3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype'); */
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'mono45-headline';
  src: url('https://use.typekit.net/af/9f5dfc/00000000000000003b9b41a4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
    format('woff2');
  /* url('https://use.typekit.net/af/9f5dfc/00000000000000003b9b41a4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/9f5dfc/00000000000000003b9b41a4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype'); */
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'mono45-headline';
  src: url('https://use.typekit.net/af/0f5d0c/00000000000000003b9b41a2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
    format('woff2');
  /* url('https://use.typekit.net/af/0f5d0c/00000000000000003b9b41a2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/0f5d0c/00000000000000003b9b41a2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype'); */
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'ropa-sans-pro';
  src: url('https://use.typekit.net/af/7fa0d5/00000000000000003b9af9ad/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
    format('woff2');
  /* url('https://use.typekit.net/af/7fa0d5/00000000000000003b9af9ad/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/7fa0d5/00000000000000003b9af9ad/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype'); */
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'ropa-sans-pro';
  src: url('https://use.typekit.net/af/7bd56a/00000000000000003b9af9ae/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
    format('woff2');
  /* url('https://use.typekit.net/af/7bd56a/00000000000000003b9af9ae/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/7bd56a/00000000000000003b9af9ae/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype'); */
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'ropa-sans-pro';
  src: url('https://use.typekit.net/af/e688b4/00000000000000003b9af9af/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
    format('woff2');
  /* url('https://use.typekit.net/af/e688b4/00000000000000003b9af9af/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/e688b4/00000000000000003b9af9af/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype'); */
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'ropa-sans-pro';
  src: url('https://use.typekit.net/af/8c1821/00000000000000003b9af9b0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
    format('woff2');
  /* url('https://use.typekit.net/af/8c1821/00000000000000003b9af9b0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/8c1821/00000000000000003b9af9b0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype'); */
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'ropa-sans-pro';
  src: url('https://use.typekit.net/af/ff3311/00000000000000003b9af9b3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
    format('woff2');
  /* url('https://use.typekit.net/af/ff3311/00000000000000003b9af9b3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/ff3311/00000000000000003b9af9b3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype'); */
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

.tk-mono45-headline {
  font-family: 'mono45-headline', monospace;
}
.tk-ropa-sans-pro {
  font-family: 'ropa-sans-pro', sans-serif;
}
